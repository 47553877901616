
@media screen and (max-width: 480px){
    .modal-footer{
        justify-content: center;
    }
}

.tooltip {
    z-index: 1073;
}

