.invoice-table-bordered {
  border: 1px solid #ddd;
}

table.invoice-table th,
table.invoice-table td {
  padding: 0.15rem 0.25rem;
}

table.invoice-table-bordered th,
table.invoice-table-bordered td {
  border: 1px solid #ddd;
}
